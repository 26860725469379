.button {
  --button-color: theme("colors.white");
  --button-width: theme("width.full");
  --button-font-size: theme("fontSize.sm");
  --button-background-color: theme("colors.bg.blue");
  --button-before-background-color: theme("colors.white");
  --button-border-color: theme("colors.bg.blue");
  --button-border-width: 2px;
  --button-padding: theme("spacing.3") theme("spacing.4");

  position:relative;
  z-index:0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--button-width);
  overflow: hidden;
  gap:theme("gap.2");
  color: var(--button-color);
  font-weight: bold;
  background-color: var(--button-background-color);
  border: var(--button-border-width) solid var(--button-border-color);
  padding: var(--button-padding);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
  border-color 0.3s ease-in-out;

  @media (min-width: theme("screens.md")) {
    --button-font-size: theme("fontSize.base");
    --button-padding: theme("spacing.4") theme("spacing.7");
  }

  &::before{
    content: '';
    position: absolute;
    z-index: -1;
    top:50%;
    left:50%;
    width: 120%;
    padding-top: 120%;
    background-color: var(--button-before-background-color);
    border-radius: theme("borderRadius.full");
    transform-origin: top left;
    transform: scale(0) translate(-50%,-50%);
    transition: all 0.3s ease-in-out;
  }

  &:hover{    
    --button-background-color: theme("colors.white");
    --button-color: theme("colors.bg.blue");
    &::before{
      transform: scale(1) translate(-50%,-50%);
    }
  }


  &[data-theme="secondary"]{
      --button-background-color: theme("colors.white");
      --button-color: theme("colors.bg.blue");
      &:hover{
        --button-background-color: theme("colors.bg.blue");
        --button-color: theme("colors.white");
        --button-border-color: theme("colors.bg.blue");
      }

    &::before{
      --button-before-background-color: theme("colors.bg.blue");
    }
  }

  &[data-size="small"]{
      --button-font-size: theme("fontSize.xs");
      --button-padding: theme("spacing.1") theme("spacing.4");
  }

  &[data-size="medium"]{
      --button-border-width: 4px;
      --button-font-size: theme("fontSize.lg");
      --button-padding: theme("spacing[3.5]") theme("spacing.12");
  }

  &:disabled{
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
}
