@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}

.fadeOut {
	opacity: 1;
  animation : fadeOut 1.2s;
  animation-fill-mode: both;
}
