.animate{
	&.a-hovering {
	  transform: translateY(0px);
	}
	&.a-fadeInUp {
	  @apply opacity-0;
	  transform: translateY(10px);
	}
    &.a-fill{
        @apply p-1 relative inline-block;
        span{
            @apply relative z-20 block;
        }
        &:before{
            content: "";
            @apply absolute top-0 left-0 z-10 w-full h-full transform scale-0 bg-bg-skyBlue;
        }
        &:after{
            content: "";
            @apply absolute top-0 left-0 z-30 w-full h-full transform scale-0 bg-gray-400;
        }
    }
    &[data-animated="true"]{
		&.a-hovering{
			animation: hovering 4s ease infinite forwards;	
		}
		&.a-fadeInUp{
			animation: fadeInUp 1s ease forwards;
		}
		&.a-fill{
			span{
				animation: 0.7s fillIn forwards 0.8s;
			}
			&::after{
				animation: 0.7s fillOut forwards 0.8s;
			}
			&::before{
				animation: 0.7s fillIn forwards 0.8s;
			}
		}
    }
}

@keyframes hovering {
	0% {
		transform: translateY(0px)
	}
	40% {
		transform: translateY(10px)
	}
	80% {
		transform: translateY(-10px)
	}
	100% {
		transform: translateY(0px)
	}
}
  
@keyframes fillIn {
	0% {
		transform: scale(0,1);
		transform-origin: left center;
	}

	40% {
		transform: scale(1,1);
		transform-origin: left center;
	}

	41% {
		transform: scale(1,1);
		transform-origin: right center;
	}

	100% {
		transform: scale(1,1);
		transform-origin: right center;
	}
}


@keyframes fillOut {
	0% {
		transform: scale(0,1);
		transform-origin: left center;
	}

	40% {
		transform: scale(1,1);
		transform-origin: left center;
	}
	41% {
		transform: scale(1,1);
		transform-origin: right center;
	}

	100% {
		transform: scale(0,1);
		transform-origin: right center;
	}
}


@keyframes fadeInUp {
	0% {
	  opacity: 0;
	  transform: translateY(10px);
	}
  
	40% {
	  opacity: 0.7;
	  transform: translateY(0);
	}
  
	100% {
	  opacity: 1;
	  transform: translateY(0);
	}
  }