@import "@/assets/styles/mixins";
@import "@/assets/styles/functions";

.header {

  .header__item{
    &:hover{
      .submenu {
        visibility: visible;
        opacity: 1;
      }
      .header__icon {
        &[data-icon="plus"]{
          &::before {
            opacity: 0;
          }
          &::after{
            background: theme("colors.bg.blue");
          }
        }
      }
    }
    .header__link {
      padding-top: rem(22);
      padding-bottom: rem(22);
      font-weight: bold;
      letter-spacing: 0.05em;
      display: inline-block;
      position: relative;
      &[data-underlined="true"]{
        &::after{
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: rem(11);
          height: rem(3);
          border-radius:theme("borderRadius.DEFAULT");
          opacity: 0;
          background: theme("colors.bg.blue");
          transition: opacity 0.3s;
        }
      }
      &:hover{
        &[data-underlined="true"]{
          &::after{
            opacity: 1;
          }
        }
      }
    }
    .submenu {
      display: grid;
      gap: theme("gap.6");
      padding: theme("padding.6");
      position: absolute;
      top: rem(60);
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.1s;
      visibility: hidden;
      opacity: 0;
      width: rem(180);
      background:theme("colors.white");
      box-shadow: 0 0 rem(6) rgba(0,0,0,0.08);
      border-radius: theme("borderRadius.xl");
      .submenu__link {
        font-weight: bold;
        font-size: theme("fontSize.sm");
        &:hover{
          color: theme("colors.bg.blue");
        }
      }
    }
    .header__icon {
      position: relative;
      display: inline-block;
      &[data-icon="plus"]{
        width: rem(10);
        height: rem(10);
        margin-left: theme("margin.1");
        &::before,
        &::after  {
          content: '';
          display: inline-block;
          position: absolute;
          left: 50%;
          top: -1px;
          width: 2px;
          height: 10px;
          background-color: #111;
          border-radius: theme("borderRadius.DEFAULT");
          transition: all 0.3s;
        }
        &::before {
          opacity: 1;
        }
        &::after {
          transform: rotate(90deg);
        }
      }
    }
  }

  .hamburger {
    padding-left: theme("padding.5");
    padding-right: theme("padding.5");
    height: rem(44);
    background: theme("colors.bg.blue");
    color: theme("colors.white");
    border-bottom-left-radius: theme("borderRadius.xl") ;
    display: flex;
    align-items: center;
    gap: theme("gap.4");
    position: relative;
    z-index: 1000;

    .hamburger__text{
      font-size: theme("fontSize.sm");
      font-weight: bold;
      letter-spacing: -0.01em;
      transition: all 0.3s;
    }

    .hamburger__line {
      display: block;
      border-radius:  theme("borderRadius.DEFAULT");
      width: theme("width.6");
      height: 2px;
      margin-left: auto;
      margin-right: auto;
      background: theme("colors.white");
      transition: all 0.3s;
      margin-bottom: theme("margin.1");
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .drawer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: theme("colors.white");
    transition: all 0.5s;
    z-index: 50;
    opacity: 0;
    visibility: hidden;

    .drawer__menu {
      height: 100vh;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
    }
    .drawer__menuInner {
      padding-top: theme("padding.8");
      padding-right: theme("padding.6");
      padding-left: theme("padding.6");
      border-bottom: 4px solid rgba(17,17,17,0.4);
    }
  }

  &[data-nav-show="true"] {
    .hamburger {
      background: theme("colors.white");
    }
    .hamburger__text {
      color: theme("colors.bg.blue");
    }
    .hamburger__line {
      background: theme("colors.bg.blue");
      margin-bottom: 0;
      &:first-child {
        transform: translateY(rem(4)) rotate(150deg);
      }
      &:nth-child(2){
        opacity: 0;
      }
      &:last-child {
        transform: translateY(rem(0)) rotate(-150deg);
      }
    }
    .drawer {
      opacity: 1;
      visibility:visible;
    }
  }
}
