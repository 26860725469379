.c-policy {
  &[data-page="privacy"]{
    color: #333;
    display: grid;
    gap: theme("gap.20");
    padding: 0 theme("padding.3");
    @media (min-width: theme("screens.md")){
      padding: 0 theme("padding.56");
    }
    .c-policy-list {
      &[data-type="primary"]{
        .primary-title {
          font-size: theme("fontSize.xl");
          @media (min-width: theme("screens.md")){
            font-size: theme("fontSize.2xl");
          }
        }
      }
      &[data-type="secondary"]{
        .secondary-title {
          font-size: theme("fontSize.lg");
          @media (min-width: theme("screens.md")){
            font-size: theme("fontSize.22");
          }
        }
      }
    }
  }

  li,
  div {
    font-size: theme("fontSize.sm");
    line-height: 2;
  }

  a {
    text-decoration: underline;
    transition: opacity 0.3s;
    color: theme("colors.bg.blue");
    &:hover{
      opacity: 0.5;
    }
  }

  .c-policy-list {
    display: grid;
    &[data-type="primary"]{
      gap: theme('gap.10');
      > li {
        counter-increment: count 1;
        .primary-title {
          font-size: theme("fontSize.base");
          line-height: 1.5;
          position: relative;
          padding: 0 0 theme("padding.2") theme("padding.6");
          margin-bottom: theme("margin.5");
          border-bottom: 3px solid theme("colors.bg.blue");
          &::before{
            content: counter(count)".";
            position: absolute;
            left:0;
          }
        }
      }
    }
    &[data-type="secondary"]{
      gap: theme('gap.8');
      > li {
        counter-increment: _count 1;
        .secondary-title {
          position: relative;
          font-size: theme("fontSize.base");
          margin-bottom: theme("margin.4");
          padding-left: theme("padding.6");
          line-height: 1.5;
          &::before{
            content: "("counter(_count)")";
            position: absolute;
            left:0;
          }
        }
      }
    }
  }

  .c-policy-note {
    display: grid;
    gap: theme("gap.4");
    padding: theme("padding.6");
    &[data-bg="primary"]{
      background: theme("colors.white");
    }
    &[data-bg="secondary"]{
      background: theme("colors.bg.gray");
    }
  }
}
