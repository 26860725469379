@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./mixins";
@import "./functions";
@import "./animations";
@import './parts/index.scss';
@import './pages/index.scss';

@function vw__base($value) {
    $rate: 100 / 375;
    @return $rate * $value * 1vw;
}

@function vw__md($value) {
    $rate: 100 / 1366;
    @return $rate * $value * 1vw;
}
  
  
@layer base {
    html {
        font-family:'Noto Sans JP', "游ゴシック体",YuGothic,"游ゴシック Medium","Yu Gothic Medium","游ゴシック","Yu Gothic",sans-serif;
        font-size: vw__md(16);
        @media screen and (max-width: theme("screens.md")){
            font-size: vw__base(16);
        }
        @media screen and (min-width: theme("screens.xl")){
            font-size: 16px;
        }
        @apply scroll-pt-14 md:scroll-pt-32;
    }

    body{
        background-color: theme("colors[white]");
        &.disabled-scroll {
            overflow: hidden;
        }
    }
}


.container{
    width: 100%;
    max-width: theme("screens[xl]");
}


.modal-content{
    .modal-link{
        position:relative;
        @apply text-bg-blue flex justify-between items-center pb-2 font-bold;
        &:before,
        &:after{
            content: "";
            position: absolute;
            bottom:0;
            left:0;
            width: 100%;
            height: 2px;
            @apply bg-white;
        }
        &:after{
            @apply bg-bg-blue transform scale-x-0 transition-transform duration-700 origin-right;
        }
        &:hover{
            &:after{
                @apply scale-x-100 origin-left;
            }
        }
    }
}

.u-attend {
    @apply grid grid-cols-[max-content_minmax(0,1fr)] gap-x-2;
    &:before {
      content: '・';
    }
  }

.article {
    @apply block pb-4 relative;
    &:before,
    &:after{
        content: "";
        position: absolute;
        bottom:0;
        left:0;
        width: 100%;
        height: 2px;
        @apply bg-bg-blue md:bg-white;
    }
    &:after{
        @apply bg-bg-blue transform scale-x-0 transition-transform duration-700 origin-right;
    }
    &:hover{
        &:after{
            @apply scale-x-100 origin-left;
        }
        .article-image{
            img{
                @apply md:scale-110;
            }
        }
        .article-date,
        .article-title{
            @apply text-bg-blue;
        }
    }

    .article-image {
        @apply border border-[#E2E9EB] mb-6 w-full relative overflow-hidden;
        img{
        @apply absolute h-full w-full top-0 ease-out duration-300;
        }
    }
    .article-date{
        @apply text-xs mb-4 ease-out duration-300;
    }
    .article-title {
        @apply font-bold leading-normal ease-out duration-300;
    }
}
